import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Popover, Table, TableBody, TableRow, TableCell, Typography, colors } from '@mui/material';
import { FC, MouseEvent, memo, useCallback, useState } from 'react';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsNotAllocReason } from 'src/entities/PlanningsNotAllocReasons.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';

type Props = {
  filteredNotAllocReasons: PlanningsNotAllocReason[];
  notAllocDeliveries: PlanningsDeliveryEntity[];
  notAllocTrucks: PlanningsTruckEntity[];
  notAllocDrivers: PlanningsDriverEntity[];
};
export const NotAllocReasonPresenter: FC<Props> = memo(({
  filteredNotAllocReasons,
  notAllocDeliveries,
  notAllocTrucks,
  notAllocDrivers,
}) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement>(null);

  const handlePopoverOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setPopoverAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setPopoverAnchorEl(null);
  }, []);

  const handlePopoverClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <>
      <IconButton
        onClick={handlePopoverOpen}
      >
        <InfoOutlinedIcon sx={{ color: colors.blue[900] }} />
      </IconButton>
      <Popover
        id={popoverAnchorEl ? 'not-alloc-reason-popover' : undefined}
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        onClick={handlePopoverClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Table>
          <TableBody>
            {filteredNotAllocReasons.map((reason) => {
              const delivery = notAllocDeliveries.find((it) => it.id === reason.delivery_id);
              const truck = delivery ? notAllocTrucks.find((it) => it.id === delivery.truckId) : null;
              const driver = delivery ? notAllocDrivers.find((it) => it.id === delivery.driverId) : null;

              return (
                <TableRow key={[delivery?.id, truck?.id, driver?.id].join('-')}>
                  <TableCell sx={{ p: 1 }}><Typography noWrap>{truck?.licensePlateValue}</Typography></TableCell>
                  <TableCell sx={{ p: 1 }}><Typography noWrap>{driver?.name}</Typography></TableCell>
                  <TableCell sx={{ p: 1 }}><Typography noWrap>{reason?.message}</Typography></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Popover>
    </>
  );
});
