import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined, HelpOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box, Button, Divider, FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Stack, Switch, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, TextareaAutosize, TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import LicenseContext from 'src/contexts/LicenseContext';
import { DeliveryListGroupingValues, LicenseEntity, LoadingUnloadingDuration } from 'src/entities/licenseEntity';
import { useQueryCompany } from 'src/hooks/useQueryCompany';
import * as yup from 'yup';

import arrayUtil from '../../utils/array.util';

const bigTruckCombination = (_value: number, context: yup.TestContext) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    // eslint-disable-next-line camelcase
    big_weight_truck_threshold, big_volume_truck_threshold,
    // eslint-disable-next-line camelcase
    big_weight_order_threshold, big_volume_order_threshold,
    // eslint-disable-next-line camelcase
    small_weight_order_threshold, small_volume_order_threshold
  } = context.parent;
  // eslint-disable-next-line camelcase
  if ((big_weight_order_threshold || big_volume_order_threshold
    // eslint-disable-next-line camelcase
    || small_weight_order_threshold || small_volume_order_threshold)
    // eslint-disable-next-line camelcase
    && (!big_weight_truck_threshold && !big_volume_truck_threshold)) {
      return false;
  }
  return true;
};

const packingStyleSchema = yup.object({
  name: yup.string().required(),
  weight: yup
    .number()
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  volume: yup
    .number()
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
});

const schema = yup.object({
  availability_of_expressway: yup.boolean().required(),
  orders_limit_per_driver: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  shuttle_limit: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  unloading_position_limit_by_a_shuttle: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  driver_cost_yen_per_hours: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  truck_expressway_fee_yen_per_shift: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  truck_fuel_cost_yen_per_km: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  truck_insurance_fee_yen_per_day: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  truck_repair_cost_yen_per_day: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  packing_style_and_weight_volumes: yup.array().of(packingStyleSchema),
  big_weight_truck_threshold: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    .test(
      'combination_validation',
      '詳細条件を設定した場合は、大型車両の条件を入力してください。',
      bigTruckCombination,
    ),
  big_volume_truck_threshold: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    .test(
      'combination_validation',
      '詳細条件を設定した場合は、大型車両の条件を入力してください。',
      bigTruckCombination,
    ),
  big_weight_order_threshold: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  big_volume_order_threshold: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  small_weight_order_threshold: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  small_volume_order_threshold: yup
    .number()
    .integer('整数で入力してください')
    .positive('0以下は入力できません')
    .typeError('半角数で入力してください')
    .nullable()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  });

const EngineConfigPresenter: FC = memo(() => {
  const licenseContext = useContext(LicenseContext);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [companyId, setCompanyId] = useState(0);
  const { data: companyData } = useQueryCompany(companyId);

  const [customInputFieldOne, setCustomInputFieldOne] = useState<string>('');
  const updateCustomInputFieldOne = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (customInputFields.includes(event.target.value)) {
      enqueueSnackbar('既に存在する独自入力項目です', { variant: 'warning' });
      return;
    }

    setCustomInputFieldOne(
      event.target.value
    );
  };
  const [customInputFieldTwo, setCustomInputFieldTwo] = useState<string>('');
  const updateCustomInputFieldTwo = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (customInputFields.includes(event.target.value)) {
      enqueueSnackbar('既に存在する独自入力項目です', { variant: 'warning' });
      return;
    }

    setCustomInputFieldTwo(
      event.target.value
    );
  };
  const [customInputFieldThree, setCustomInputFieldTree] = useState<string>('');
  const updateCustomInputFieldThree = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (customInputFields.includes(event.target.value)) {
      enqueueSnackbar('既に存在する独自入力項目です', { variant: 'warning' });
      return;
    }

    setCustomInputFieldTree(
      event.target.value
    );
  };
  const [customInputFieldFour, setCustomInputFieldFour] = useState<string>('');
  const updateCustomInputFieldFour = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (customInputFields.includes(event.target.value)) {
      enqueueSnackbar('既に存在する独自入力項目です', { variant: 'warning' });
      return;
    }

    setCustomInputFieldFour(
      event.target.value
    );
  };
  const [customInputFieldFive, setCustomInputFieldFive] = useState<string>('');
  const updateCustomInputFieldFive = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (customInputFields.includes(event.target.value)) {
      enqueueSnackbar('既に存在する独自入力項目です', { variant: 'warning' });
      return;
    }

    setCustomInputFieldFive(
      event.target.value
    );
  };

  const customInputFields = [
    customInputFieldOne,
    customInputFieldTwo,
    customInputFieldThree,
    customInputFieldFour,
    customInputFieldFive,
  ].filter((maybe) => maybe);

  useEffect(() => {
    if (!companyData) return;

    setCustomInputFieldOne(
      companyData.custom_input_fields[0] || ''
    );
    setCustomInputFieldTwo(
      companyData.custom_input_fields[1] || ''
    );
    setCustomInputFieldTree(
      companyData.custom_input_fields[2] || ''
    );
    setCustomInputFieldFour(
      companyData.custom_input_fields[3] || ''
    );
    setCustomInputFieldFive(
      companyData.custom_input_fields[4] || ''
    );
  }, [companyData]);

  const {
    reset,
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<LicenseEntity>({
    resolver: yupResolver(schema),
  });

  const { fields } = useFieldArray({
    control,
    name: 'packing_style_and_weight_volumes'
  });

  const minutesToSeconds = (value: number) => ((value !== null && value !== undefined) ? value * 60 : null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** 有料道路の利用設定を配車ダイアログに反映する */
  const updatePlanningPresenterSettings = useCallback((entity: LicenseEntity) => {
    const json = localStorage.getItem('PlanningPresenterSettings');
    if (!json) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const obj = JSON.parse(json);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    obj.availabilityOfExpressway = entity.availability_of_expressway;
    localStorage.setItem('PlanningPresenterSettings', JSON.stringify(obj));
  }, []);

  const buttonOnClick: SubmitHandler<LicenseEntity> = async (data: LicenseEntity) => {
    const companyRequestPath = '/api/v2/company';

    data.delivery_list_grouping = deliverylListGrouping;
    data.custom_input_fields = customInputFields;
    data.travel_time_scale = ((travelTimeScale * -1) + 100.0) / 100;

    setIsLoading(true);

    const loadingDefaultSeconds = loadingDefaultMinutes > 0 ? minutesToSeconds(loadingDefaultMinutes) : null;
    const unloadingDefaultSeconds = unloadingDefaultMinutes > 0 ? minutesToSeconds(unloadingDefaultMinutes) : null;
    const requestEntity = {
      ...data,
      loading_duration_seconds: loadingDefaultSeconds,
      unloading_duration_seconds: unloadingDefaultSeconds,
      loading_unloading_duration_table: loadingUnloadingDurations
    };

    try {
      await axios.post(companyRequestPath, requestEntity);
      // eslint-disable-next-line no-void
      void queryClient.invalidateQueries(['company', { companyId }]);
      updatePlanningPresenterSettings(requestEntity);
      enqueueSnackbar('更新が完了しました');
    } catch (e) {
      enqueueSnackbar('エラーが発生しました');
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!companyData) return;

    // eslint-disable-next-line camelcase
    const { loading_unloading_duration_table, loading_duration_seconds, unloading_duration_seconds, ...newCompanyData } = companyData;

    reset({
      ...newCompanyData,
      custom_input_fields: []
    });
  }, [companyData, reset]);

  useEffect(() => {
    if (!licenseContext.config) return;

    setCompanyId(licenseContext.config.selected_company_id);
  }, [licenseContext.config]);

  const [loadingDefaultMinutes, setLoadingDefaultMinutes] = useState<number>(0);
  const [unloadingDefaultMinutes, setUnloadingDefaultMinutes] = useState<number>(0);
  const [loadingUnloadingDurations, setLoadingUnloadingDurations] = useState<LoadingUnloadingDuration[]>([]);
  const [loadingUnloadingDurationErrors, setLoadingUnloadingDurationErrors] = useState<{ idx: number; key: string, message: string }[]>([]);

  const updateLoadingDefaultMinutes = (value: number) => {
    if (Number.isNaN(value)) return;
    if (value < 0) return;

    setLoadingDefaultMinutes(value);
  };

  const updateUnloadingDefaultMinutes = (value: number) => {
    if (Number.isNaN(value)) return;
    if (value < 0) return;

    setUnloadingDefaultMinutes(value);
  };

  const loadingUnloadingDurationDefault: LoadingUnloadingDuration = {
    weightKg: arrayUtil.max(loadingUnloadingDurations.map((it) => it.weightKg)) + 1,
    volumeM3: arrayUtil.max(loadingUnloadingDurations.map((it) => it.volumeM3)) + 1,
    loadingDurationSeconds: loadingDefaultMinutes * 60 || Math.ceil(arrayUtil.max(loadingUnloadingDurations.map((it) => it.loadingDurationSeconds))),
    unloadingDurationSeconds: unloadingDefaultMinutes * 60 || Math.ceil(arrayUtil.max(loadingUnloadingDurations.map((it) => it.unloadingDurationSeconds))),
  };

  const initializeLoadingUnloadingDuration = () => {
    setLoadingUnloadingDurations([loadingUnloadingDurationDefault]);
  };

  const addLoadingUnloadingDuration = (index: number) => {
    const newLoadingUnloadingDurations = loadingUnloadingDurations.map((loadingUnloadingDuration, i) => {
      if (i !== (index)) return loadingUnloadingDuration;
      return [
        loadingUnloadingDuration,
        loadingUnloadingDurationDefault
      ];
    });
    setLoadingUnloadingDurations(newLoadingUnloadingDurations.flat());
  };

  const removeLoadingUnloadingDuration = (index: number) => {
    const newLoadingUnloadingDurations = loadingUnloadingDurations.filter((_, i) => i !== index);
    setLoadingUnloadingDurations(newLoadingUnloadingDurations);
  };

  const updateLoadingUnloadingDuration = (index: number, key: keyof LoadingUnloadingDuration, value: number) => {
    const invalidValue = Number.isNaN(value);
    if (invalidValue) return;

    const newLoadingUnloadingDurations = loadingUnloadingDurations.map((loadingUnloadingDuration, i) => {
      if (i !== index) return loadingUnloadingDuration;
      return {
        ...loadingUnloadingDuration,
        [key]: value
      };
    });

    setLoadingUnloadingDurations(newLoadingUnloadingDurations);
  };

  useEffect(() => {
    if (!licenseContext.config) return;

    const { config } = licenseContext;

    if (config.loading_duration_seconds) {
      setLoadingDefaultMinutes(config.loading_duration_seconds / 60);
    }

    if (config.unloading_duration_seconds) {
      setUnloadingDefaultMinutes(config.unloading_duration_seconds / 60);
    }

    setLoadingUnloadingDurations(config.loading_unloading_duration_table ?? []);

    if (config.delivery_list_grouping) {
      setDeliveryListGrouping(config.delivery_list_grouping);
    }
  }, [licenseContext]);

  useEffect(() => {
    const validateWeightKgPresent = (current: LoadingUnloadingDuration) => {
      if (!current.weightKg) {
        return '重量を入力してください';
      }
      return '';
    };

    const validateWeightKgGtPrev = (prev: LoadingUnloadingDuration | undefined, current: LoadingUnloadingDuration) => {
      if (prev?.weightKg && current.weightKg && prev?.weightKg > current.weightKg) {
        return '重量は小さい順に入力してください';
      }
      return '';
    };

    const validateVolumeM3GtPrev = (prev: LoadingUnloadingDuration | undefined, current: LoadingUnloadingDuration) => {
      if (!prev?.volumeM3) return '';

      if (prev.volumeM3 > current.volumeM3) {
        return '立米は小さい順に入力してください';
      }
      return '';
    };

    const validateLoadingSecondsPresent = (current: LoadingUnloadingDuration) => {
      if (!current.loadingDurationSeconds) {
        return '積込時間を入力してください';
      }
      return '';
    };

    const validateUnloadingSecondsPresent = (current: LoadingUnloadingDuration) => {
      if (!current.unloadingDurationSeconds) {
        return '降地時間を入力してください';
      }
      return '';
    };

    const errs = loadingUnloadingDurations
      .flatMap((loadingUnloadingDuration, index) => {
        const prev = loadingUnloadingDurations[index - 1];

        const weightErrors = [
          validateWeightKgPresent(loadingUnloadingDuration),
          validateWeightKgGtPrev(prev, loadingUnloadingDuration)
        ].filter((maybe) => maybe);

        return [
          { idx: index, key: 'weightKg', message: weightErrors.join('、') },
          { idx: index, key: 'volumeM3', message: validateVolumeM3GtPrev(prev, loadingUnloadingDuration) },
          { idx: index, key: 'loadingDurationSeconds', message: validateLoadingSecondsPresent(loadingUnloadingDuration) },
          { idx: index, key: 'unloadingDurationSeconds', message: validateUnloadingSecondsPresent(loadingUnloadingDuration) }
        ].filter((err) => err.message !== '');
      });

    setLoadingUnloadingDurationErrors(errs);
  }, [loadingUnloadingDurations]);

  const [deliverylListGrouping, setDeliveryListGrouping] = useState<DeliveryListGroupingValues>('groupByAddress');
  const onChangeDeliveryListGrouping = (event: ChangeEvent<HTMLInputElement>) => {
    setDeliveryListGrouping(event.target.value as DeliveryListGroupingValues);
  };

  const [travelTimeScale, setTravelTimeScale] = useState<number>(0);
  useEffect(() => {
    if (!companyData) return;

    const val = companyData.travel_time_scale ?? 1;
    setTravelTimeScale((Math.round(val * 100.0) - 100) * -1);
  }, [companyData]);

  const tooltipProps = useMemo(() => (
    {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [20, -80]
          }
        }
      ]
    }
  ), []);

  return (
    <>
      <Helmet>
        <title>計算エンジン設定</title>
      </Helmet>
      <Box p={2}>
        <Paper>
          <Stack component="form" noValidate spacing={3} divider={<Divider />} p={3} sx={{ marginBottom: '80px' }}>
            <Stack>
              <Typography variant="h4" pb={2}>グローバル設定</Typography>
              <Stack direction="row" gap={2} justifyItems="center">
                <Stack direction="row" gap={2} justifyItems="center" sx={{ mb: 2 }}>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="ピストン輸送の制限回数"
                    id="shuttle_limit"
                    {...register('shuttle_limit')}
                    error={'shuttle_limit' in errors}
                    helperText={errors.shuttle_limit?.message}
                  />
                </Stack>
                <Stack>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="１回転あたりの上限納品先件数"
                    id="unloading_position_limit_by_a_shuttle"
                    {...register('unloading_position_limit_by_a_shuttle')}
                    error={'unloading_position_limit_by_a_shuttle' in errors}
                    helperText={errors.unloading_position_limit_by_a_shuttle?.message}
                  />
                </Stack>
                <Stack>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="割り当てる最大案件数"
                    id="orders_limit_per_driver"
                    {...register('orders_limit_per_driver')}
                    error={'orders_limit_per_driver' in errors}
                    helperText={errors.orders_limit_per_driver?.message}
                  />
                </Stack>
              </Stack>
              <Stack direction="column">
                <Stack direction="row" gap={2} justifyItems="center" sx={{ mb: 2 }}>
                  <FormControl
                    variant="standard"
                  >
                    <Controller
                      control={control}
                      name="availability_of_expressway"
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={(
                            <Switch
                              checked={getValues('availability_of_expressway')}
                            />
                          )}
                          label="有料道路の利用を許可する"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                  >
                    <Controller
                      control={control}
                      name="concurrent_all_or_nothing"
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={(
                            <Switch
                              checked={getValues('concurrent_all_or_nothing')}
                            />
                          )}
                          label="同一ロケーションで複数台になってしまう場合は割り当てない"
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" gap={2} justifyItems="center" sx={{ mb: 2 }}>
                  <FormControl
                    variant="standard"
                  >
                    <Controller
                      control={control}
                      name="activate_constraint_load_after_not_cleared"
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={(
                            <Switch
                              checked={getValues('activate_constraint_load_after_not_cleared')}
                            />
                          )}
                          label="空荷時のみ積込を許可する"
                        />
                      )}
                    />
                  </FormControl>
                  <Stack direction="row" alignItems="center">
                    <FormControl
                      variant="standard"
                    >
                      <Controller
                        control={control}
                        name="load_after_initial_stock"
                        render={({ field }) => (
                          <FormControlLabel
                            {...field}
                            control={(
                              <Switch
                                checked={getValues('load_after_initial_stock')}
                              />
                            )}
                            label="宵積み時に納品前の積込を許可する"
                          />
                        )}
                      />
                    </FormControl>
                    <Tooltip
                      title="宵積み時、既存の荷物を降ろす前でもトラックに空きがあれば荷物を積み込むことができます。"
                    >
                      <HelpOutline />
                    </Tooltip>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  whiteSpace: 'nowrap'
                }}
              >
                移動速度の調整
              </Stack>
              <Stack direction="row" gap={2} justifyItems="center" sx={{ width: 400 }}>
                <Slider
                  step={1}
                  min={-50}
                  max={50}
                  valueLabelDisplay="auto"
                  // value={0}
                  value={travelTimeScale}
                  onChange={(_, value) => setTravelTimeScale(value as number)}
                />
                <Typography noWrap sx={{ width: 170 }}>
                  {`${Math.abs(travelTimeScale)}% ${travelTimeScale >= 0 ? '速くする' : '遅くする'}`}
                </Typography>
              </Stack>
              <Divider sx={{ my: 2 }} />
              <Stack>
                <Typography variant="h4" pb={2}>大型車両と荷物の割り付け条件設定</Typography>
                <Stack direction="row" sx={{ mb: 1 }}>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label=""
                    id="big_weight_truck_threshold"
                    {...register('big_weight_truck_threshold')}
                    error={'big_weight_truck_threshold' in errors}
                    helperText={errors.big_weight_truck_threshold?.message}
                  />
                  <Typography variant="body1">kg 以上、または</Typography>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label=""
                    id="big_volume_truck_threshold"
                    {...register('big_volume_truck_threshold')}
                    error={'big_volume_truck_threshold' in errors}
                    helperText={errors.big_volume_truck_threshold?.message}
                  />
                  <Typography variant="body1">m3以上の車両に対して</Typography>
                </Stack>
                <Stack direction="row" sx={{ ml: 2, mb: 1 }}>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label=""
                    id="big_weight_order_threshold"
                    {...register('big_weight_order_threshold')}
                    error={'big_weight_order_threshold' in errors}
                    helperText={errors.big_weight_order_threshold?.message}
                  />
                  <Typography variant="body1">kg・</Typography>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label=""
                    id="big_volume_order_threshold"
                    {...register('big_volume_order_threshold')}
                    error={'big_volume_order_threshold' in errors}
                    helperText={errors.big_volume_order_threshold?.message}
                  />
                  <Typography variant="body1">m3以上の荷物を優先的に割り付ける</Typography>
                  <Tooltip
                    title="納品地点ごとに合算した荷物量の数字"
                    PopperProps={tooltipProps}
                  >
                    <HelpOutline sx={{ ml: 1 }} />
                  </Tooltip>
                </Stack>
                <Stack direction="row" sx={{ ml: 2 }}>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label=""
                    id="small_weight_order_threshold"
                    {...register('small_weight_order_threshold')}
                    error={'small_weight_order_threshold' in errors}
                    helperText={errors.small_weight_order_threshold?.message}
                  />
                  <Typography variant="body1">kg・</Typography>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label=""
                    id="small_volume_order_threshold"
                    {...register('small_volume_order_threshold')}
                    error={'small_volume_order_threshold' in errors}
                    helperText={errors.small_volume_order_threshold?.message}
                  />
                  <Typography variant="body1">m3以下の荷物は割り付けない</Typography>
                  <Tooltip
                    title="納品地点ごとに合算した荷物量の数字"
                    PopperProps={tooltipProps}
                  >
                    <HelpOutline sx={{ ml: 1 }} />
                  </Tooltip>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Typography variant="h4" pb={2}>コスト設定</Typography>
              <Stack direction="row" gap={2} justifyItems="center">
                <Stack>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="時給"
                    id="driver_cost_yen_per_hours"
                    {...register('driver_cost_yen_per_hours')}
                    error={'driver_cost_yen_per_hours' in errors}
                    helperText={errors.driver_cost_yen_per_hours?.message}
                  />
                </Stack>
                <Stack>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="1kmあたりの燃料費"
                    id="truck_fuel_cost_yen_per_km"
                    {...register('truck_fuel_cost_yen_per_km')}
                    error={'truck_fuel_cost_yen_per_km' in errors}
                    helperText={errors.truck_fuel_cost_yen_per_km?.message}
                  />
                </Stack>
                <Stack>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="1日あたりの保険料"
                    id="truck_insurance_fee_yen_per_day"
                    {...register('truck_insurance_fee_yen_per_day')}
                    error={'truck_insurance_fee_yen_per_day' in errors}
                    helperText={errors.truck_insurance_fee_yen_per_day?.message}
                  />
                </Stack>
                <Stack>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="1日あたりの車両修繕費"
                    id="truck_repair_cost_yen_per_day"
                    {...register('truck_repair_cost_yen_per_day')}
                    error={'truck_repair_cost_yen_per_day' in errors}
                    helperText={errors.truck_repair_cost_yen_per_day?.message}
                  />
                </Stack>
                <Stack>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="配送計画あたりの高速代"
                    id="truck_expressway_fee_yen_per_shift"
                    {...register('truck_expressway_fee_yen_per_shift')}
                    error={'truck_expressway_fee_yen_per_shift' in errors}
                    helperText={errors.truck_expressway_fee_yen_per_shift?.message}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack
              spacing={2}
            >
              <Typography variant="h4">表示設定</Typography>
              <Stack>
                <Typography variant="h6">
                  配送リストの地点表示
                </Typography>
                <FormControl
                  fullWidth
                >
                  <RadioGroup
                    row
                    value={deliverylListGrouping ?? 'groupByAddress'}
                    onChange={onChangeDeliveryListGrouping}
                  >
                    <FormControlLabel value="groupByAddress" control={<Radio />} label="住所でまとめる" />
                    <FormControlLabel value="groupByName" control={<Radio />} label="名称でまとめる" />
                  </RadioGroup>
                </FormControl>
              </Stack>
              <Typography variant="h6">
                配送指示書に定型文を表示
              </Typography>
              <TextareaAutosize
                style={{ width: '80%' }}
                id="operation_direction_memo"
                {...register('operation_direction_memo')}
                minRows={3}
              />
            </Stack>
            <Stack
              spacing={2}
            >
              <Typography variant="h4">荷捌き時間設定</Typography>
              <Stack>
                <Typography variant="h6">
                  デフォルト値設定
                </Typography>
                <Stack
                  direction="row"
                  gap={2}
                  justifyItems="center"
                >
                  <Stack>
                    <TextField
                      size="small"
                      type="number"
                      variant="standard"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="積込時間(分)"
                      value={loadingDefaultMinutes || ''}
                      onChange={(e) => updateLoadingDefaultMinutes(Number(e.target.value))}
                    />
                  </Stack>
                  <Stack>
                    <TextField
                      size="small"
                      type="number"
                      variant="standard"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="降地時間(分)"
                      value={unloadingDefaultMinutes || ''}
                      onChange={(e) => updateUnloadingDefaultMinutes(Number(e.target.value))}
                    />
                  </Stack>
                </Stack>
              </Stack>

              <Stack>
                {!loadingUnloadingDurations.length ? (
                  <Stack
                    direction="row"
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        initializeLoadingUnloadingDuration();
                      }}
                    >
                      重量・体積ごとの個別荷捌き時間を設定する
                    </Button>
                  </Stack>
                ) : (
                  <Typography variant="h6">
                    個別設定
                  </Typography>
                )}
                {!!loadingUnloadingDurations.length && loadingUnloadingDurations.map((loadingUnloadingDuration, index) => (
                  <Stack
                    key={[
                      'loadingUnloadingDuration',
                      index
                    ].join('-')} direction="row"
                    gap={2}
                    pb={2}
                    alignItems="center"
                  >
                    <TextField
                      size="small"
                      type="number"
                      variant="standard"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="～重量(Kg)"
                      value={loadingUnloadingDuration.weightKg ?? ''}
                      onChange={(e) => updateLoadingUnloadingDuration(index, 'weightKg', Number(e.target.value))}
                      error={loadingUnloadingDurationErrors.some((err) => err.idx === index && err.key === 'weightKg')}
                      helperText={
                        loadingUnloadingDurationErrors.find((err) => err.idx === index && err.key === 'weightKg')?.message
                      }
                    />
                    <TextField
                      size="small"
                      type="number"
                      variant="standard"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="～体積(M3)"
                      value={loadingUnloadingDuration.volumeM3 ?? ''}
                      onChange={(e) => updateLoadingUnloadingDuration(index, 'volumeM3', Number(e.target.value))}
                      error={loadingUnloadingDurationErrors.some((err) => err.idx === index && err.key === 'volumeM3')}
                      helperText={
                        loadingUnloadingDurationErrors.find((err) => err.idx === index && err.key === 'volumeM3')?.message
                      }
                    />
                    <TextField
                      size="small"
                      type="number"
                      variant="standard"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="積込時間(分)"
                      value={loadingUnloadingDuration.loadingDurationSeconds / 60}
                      onChange={(e) => updateLoadingUnloadingDuration(index, 'loadingDurationSeconds', Number(e.target.value) * 60)}
                      error={loadingUnloadingDurationErrors.some((err) => err.idx === index && err.key === 'loadingDurationSeconds')}
                      helperText={
                        loadingUnloadingDurationErrors.find((err) => err.idx === index && err.key === 'loadingDurationSeconds')?.message
                      }
                    />
                    <TextField
                      size="small"
                      type="number"
                      variant="standard"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label="降地時間(分)"
                      value={loadingUnloadingDuration.unloadingDurationSeconds / 60}
                      onChange={(e) => updateLoadingUnloadingDuration(index, 'unloadingDurationSeconds', Number(e.target.value) * 60)}
                      error={loadingUnloadingDurationErrors.some((err) => err.idx === index && err.key === 'unloadingDurationSeconds')}
                      helperText={
                        loadingUnloadingDurationErrors.find((err) => err.idx === index && err.key === 'unloadingDurationSeconds')?.message
                      }
                    />
                    <IconButton
                      onClick={() => addLoadingUnloadingDuration(index)}
                      disabled={loadingUnloadingDurationErrors.length > 0}
                    >
                      <AddCircleOutlineOutlined />
                    </IconButton>
                    <IconButton
                      onClick={() => removeLoadingUnloadingDuration(index)}
                    >
                      <RemoveCircleOutlineOutlined />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            </Stack>
            <Stack>
              <Typography variant="h4" gutterBottom>
                荷姿ごとの変換ルール
              </Typography>
              <Typography pb={2} variant="caption">
                {`案件登録時に${companyData?.unit_used_for_calculation}が未指定の場合、指定された変換ルールが適応され${companyData?.unit_used_for_calculation}が補完されます。`}
              </Typography>
              <TableContainer
                component={Paper}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        項目
                      </TableCell>
                      <TableCell>
                        {`1個あたりの${companyData?.unit}`}
                      </TableCell>
                      {companyData?.unit_used_for_calculation === '重量と体積' && (
                        <TableCell>
                          1個あたりの体積(m3)
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((_field, idx) => (
                      <TableRow key={getValues(`packing_style_and_weight_volumes.${idx}.name`)}>
                        <TableCell>
                          {getValues(`packing_style_and_weight_volumes.${idx}.name`)}
                        </TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            variant="standard"
                            fullWidth
                            type="number"
                            required
                            {...register(`packing_style_and_weight_volumes.${idx}.weight`)}
                            error={errors?.packing_style_and_weight_volumes && !!errors?.packing_style_and_weight_volumes[idx]?.weight}
                            helperText={errors?.packing_style_and_weight_volumes && errors?.packing_style_and_weight_volumes[idx]?.weight?.message}
                          />
                        </TableCell>
                        {companyData?.unit_used_for_calculation === '重量と体積' && (
                          <TableCell>
                            <TextField
                              size="small"
                              variant="standard"
                              type="number"
                              fullWidth
                              {...register(`packing_style_and_weight_volumes.${idx}.volume`)}
                              error={errors?.packing_style_and_weight_volumes && !!errors?.packing_style_and_weight_volumes[idx]?.volume}
                              helperText={errors?.packing_style_and_weight_volumes && errors?.packing_style_and_weight_volumes[idx]?.volume?.message}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <Stack>
              <Typography variant="h4" gutterBottom>
                項目設定
              </Typography>
              <Typography pb={2} variant="caption">
                案件情報に任意の入力項目を最大5つ設定することができます。
              </Typography>
              <Stack
                direction={{ sm: 'column', md: 'row' }}
                gap={1}
                spacing={1}
              >
                <TextField
                  size="small"
                  fullWidth
                  variant="standard"
                  value={customInputFieldOne}
                  onChange={updateCustomInputFieldOne}
                />
                <TextField
                  size="small"
                  fullWidth
                  variant="standard"
                  value={customInputFieldTwo}
                  onChange={updateCustomInputFieldTwo}
                />
                <TextField
                  size="small"
                  fullWidth
                  variant="standard"
                  value={customInputFieldThree}
                  onChange={updateCustomInputFieldThree}
                />
                <TextField
                  size="small"
                  fullWidth
                  variant="standard"
                  value={customInputFieldFour}
                  onChange={updateCustomInputFieldFour}
                />
                <TextField
                  size="small"
                  fullWidth
                  variant="standard"
                  value={customInputFieldFive}
                  onChange={updateCustomInputFieldFive}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            alignItems="center"
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              padding: 2,
              bgcolor: 'background.paper',
              boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.15)',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
            }}
          >
            {
              !!loadingUnloadingDurationErrors.length && (
                <Typography color="error" variant="caption">
                  荷捌き時間設定にエラーがあります。修正してください。
                </Typography>
              )
            }
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <LoadingButton fullWidth disabled={loadingUnloadingDurationErrors.length > 0} loading={isLoading} variant="contained" size="large" onClick={handleSubmit(buttonOnClick)}>
              保存する
            </LoadingButton>
          </Stack>
        </Paper>
      </Box>
    </>
  );
});

export default EngineConfigPresenter;
